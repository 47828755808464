.option-holder {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}
.option-holder p {
  margin-bottom: 0 !important;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 20px;
  border: 2px solid gray;
  border-radius: 50%;
}

.option-icon {
  font-size: 25px !important;
  color: gray;
}

.cam-flip-btn {
  float: right;
  cursor: pointer;
}

.close-btn {
  z-index: 1000;
}

.capture-btn {
  border: none;
  padding: 10px 25px;
  border-radius: 30px;
  background-color: #573a7a;
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.recapture-btn {
  display: inline-block;
  background: rgba(0, 0, 0, 0.485);
  color: white !important;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.capture-upload-div-v2 {
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.capture-upload-div-v3 {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.capture-content-v2 {
  padding-bottom: 10px;
  border-bottom: 2px dotted rgba(60, 60, 67, 0.3);
  font-size: 20px !important;
}

.capture-error {
  box-shadow: rgba(234, 14, 14, 0.258) 0px 8px 24px !important;
  border: 1px solid red;
}

.upload-cloud-icon {
  margin-bottom: 10px;
  color: rgba(108, 99, 255, 1);
}
.input-placeholder {
  color: rgba(108, 99, 255, 1);
  font-size: 13px;
  font-weight: 500;
}

.input-placeholder-capture {
  color: rgba(108, 99, 255, 1);
  font-size: 18px;
  font-weight: 500;
}

.max-size-txt {
  color: rgba(184, 188, 202, 1);
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 8px;
}

.slider-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1px);
  width: 100%;
  height: 100vh;
  z-index: 999;
  transition: all 0.5s;
}

.manualPopup {
  position: fixed;
  bottom: 0;
  top: 35%;
  left: 28%;
  height: fit-content;
  z-index: 10000;
  background-color: rgb(255, 255, 255);
  box-shadow: #00000059 0px 5px 15px;
  border-radius: 15px;
}

.manualPopupContiner {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
}

.manualPopupActionCont {
  display: flex;
  gap: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: space-around;
}
.popUpBtnClose {
}

.manualPopupActionCard {
  height: 160px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.2px;
  padding-right: 1.2px;
  padding-top: 0.4px;
  padding-bottom: 0.4px;
  background-color: #f5f9ff;
  width: 40%;
  flex-direction: column;

  .manualPopupBtn {
    background-color: #573A7A;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 15px;

  }
}

.hide {
  display: none;
}

.slider {
  position: fixed;
  width: 100%;
  height: 140px;
  bottom: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-shadow: #00000059 0px 5px 15px;
  transition: all 0.5s;
  z-index: 1000;
  left: 0;
}

.slider.close {
  bottom: -100%;
  height: 0;
}

.bottom-menu-button {
  width: 100%;
  height: 140px;
  padding-top: 15px;
  text-transform: none !important;
  color: rgba(111, 50, 190, 1) !important;
}

.bottom-menu-ico {
  font-size: 40px;
  color: rgba(111, 50, 190, 1) !important;
  margin-bottom: 10px;
}

.camera-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 2000;
  width: 100%;
  top: 0;
  overflow: hidden;
  background-color: rgb(21, 21, 21);
}

.webcamera {
  z-index: 2000;
}

.no-face {
  border: 2px solid red;
}

.camera-close {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px !important;
  color: white;
  z-index: 2100;
}

.crop-close {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px !important;
  color: white;
}

.camera-screen::-webkit-scrollbar {
  display: none;
}

.action-buttons {
  display: flex;
  justify-content: center;
}

.action-buttons-holder {
  display: flex;
  width: 100% !important;
  position: fixed !important;
  bottom: 0 !important;
  padding: 0 20px;
  justify-content: center;
  z-index: 2500 !important;
}

.cam-flip-btn-v2 {
  color: white;
  position: absolute;
  right: 10px;
}

.camera-loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.crop-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 3000 !important;
  width: 100%;
  top: 0;
  overflow: hidden;
  background-color: rgb(21, 21, 21);
}

.doc-status-holder {
  position: absolute;
  top: -15px;
  right: 0;
  z-index: 1000;
}

.capture-disabled {
  opacity: 0.4 !important;
}

.upload-card-v3 {
  position: relative;
  width: 100%;
  height: 200px;
  background: #cbbef2;
  border-radius: 18px;
  box-shadow: -5px 8px #333333;
  padding: 20px;

  .heading {
    font-size: 25px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }

  .desc-text {
    font-size: 15px;
    font-weight: 400;
    color: white;
  }

  .button-holder {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .btn {
    background: #333333 !important;
    color: white !important;
    box-shadow: none !important;
    text-transform: none !important;
    border-radius: 12px !important;
  }
}

// .crop-comp{
//   width: 50%;
//   height: 100%;
// }

.crop-comp img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.payment-card {
  .upload-card-v3 {
    position: relative;
    width: 100%;
    height: 150px;
    background: #cbbef2;
    border-radius: 18px;
    box-shadow: -3px 5px #333333;
    padding: 20px;

    .heading {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
    }

    .desc-text {
      font-size: 15px;
      font-weight: 400;
      color: white;
    }

    .button-holder {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .btn {
      background: #333333 !important;
      color: white !important;
      box-shadow: none !important;
      text-transform: none !important;
      border-radius: 12px !important;
      font-size: 12px !important;
    }
  }
}

.upload-txt-v2 {
  font-size: 15px;
  line-height: 18px;
  color: rgb(108, 99, 255);
  margin-bottom: 0;
  text-align: center;
}
