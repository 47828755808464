.AT_body{
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: relative;
}

.AT_section{
    width: 50%;
    height: 100vh;
}

.AT_Container{
    position: relative;
    background-color: white;
    padding: 15px;
}

.AT_status_container {
    width: 100%;
    // position: relative;
    // background-color: white;
    padding: 15px;
}

.AT_info{
    position: absolute;
    background: rgba(190, 159, 225, 1);
    padding: 6px 12px;
    border-radius: 2px;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    border-radius: 2px;   
    
    .index{
        font-size: 12px;
        position: absolute;
        top: 2px;
        right: 4px;    
    }

    .info{
       text-align: center;
       padding: 6px 0px;
    }

    .pointer{
        position: absolute;
        background: rgba(190, 159, 225, 1);
        padding: 12px ;
        transform: rotate(45deg);
    }
}


.AT_controler{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    .text{
        color: white;
       font-size: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 767px){
    .AT_section{
        width: 80%;
    }
}

@media only screen and (max-width: 540px){
    .AT_section{
        width: 90%;
    }
}

.panDetailsAT1{
    left: 0;
    top: -80%;
}

.panDetailsAT2{
    left: 0;
    top: -55%;
}
// 55
.panDetailsATP{
    bottom:-12px ;
    left:14px; 
}

.cocustomerAT1 {
    left: 0;
    top: -80%;
}

.cocustomerATP {
    bottom:-12px ;
    left:14px;
}

.cocustomerAT2 {
    left: 0;
    top: -70%;
}

.loancreactionAT1{
    right : 0 ;
    bottom: -80%;
}
.loancreactionAT2{
    right : 0 ;
    bottom: -80%;
}
.loancreationATP{
    top:-12px;
    right:40px;
}

.fieldinvestigationAT1{
    left: 0;
    top: -40%;
}
.fieldinvestigationATP{
    bottom:-12px ;
    left:14px;
}

.checkstatusbutt_AT{
    position: absolute !important;
    top: 12px;
    right: 27%;
}
.checkstatusAT1{
    top: 65px;
    right: 25%;
}
.checkstatusATP{
    top: -12px;
    right: 70px;
}

@media only screen and (max-width: 410px){
    .loancreactionAT1{
        bottom: -120%;
    }
}

@media only screen and (max-width: 600px){
.checkstatusbutt_AT{
    right: 5%;
}
.checkstatusAT1{
    right: 4%;
}
}
// 600
@media (min-width: 410px) and (max-width: 480px){
    .loancreactionAT1{
        bottom: -95%;
    }
}

@media only screen and (max-width: 480px){
    .panDetailsAT1{
        top: -120%;
    }
    .cocustomerAT1{
        top: -120%;
    }
    .cocustomerAT2{
        top:-80%
    }
    

}

@media only screen and (max-width: 1100px){
    .panDetailsAT2{
        top: -75%;
    }
    .checkstatusbutt_AT{

    }
}

@media only screen and (max-width: 585px){
    .fieldinvestigationAT1{
        top:-55%
    }
}

@media only screen and (max-width: 575px){
    .checkstatusbutt_AT{
        padding-right: 10px;
        padding-left: 10px;
    }
}